@import "../../../constants";

.SideNavigationContainer {
  @media print {
    display: none;
  }
  position: relative;
  width: $sideNavigationWidth;
  min-width: fit-content;
  flex-shrink: 0;
  z-index: 1060;

  @media (max-width: $screenMedium) {
    display: none;
  }

  @media print {
    display: none !important;
  }
}

.SideNavigationDrawer .ant-drawer-content {
  background: transparent;

  .ant-drawer-body {
    padding: 0;
  }
}

.SideNavigation {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: $sideNavigationWidth;
  height: 100vh;
  background: var(--steel-900);
  transition: width 300ms ease-out;

  @keyframes navLabelFadeInAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.closed {
    .NavigationItem__Children {
      display: none;
    }
  }

  &.expand {
    width: $sideNavigationExpandedWidth;

    .NavigationItem,
    .NavItems__Support {
      .Text {
        animation-name: navLabelFadeInAnimation;
        animation-duration: 300ms;
        animation-timing-function: ease-out;
        white-space: nowrap;
      }
    }

    .HomeButton .Text {
      animation-name: navLabelFadeInAnimation;
      animation-duration: 300ms;
      animation-timing-function: ease-out;
      white-space: nowrap;
    }
  }

  &.open {
    position: static;
    width: $sideNavigationExpandedWidth;
  }

  .SideNavigation__HomeSection {
    height: $topNavigationHeight;
    background: var(--abyss-800);
    padding: var(--space-xs);

    .HomeSection__NavigationMenu {
      display: none;
      @media print {
        display: none !important;
      }
    }
  }

  .SideNavigation__NavItems {
    padding: var(--space-xs);
    overflow-y: auto;

    .NavItems__Container {
      padding-right: var(--space-xs);
    }

    .NavItems__Support {
      padding: var(--space-s);

      .Text {
        color: var(--white);
        font-size: 1rem;
        line-height: 1.375rem;
      }
      &:focus {
        outline: medium auto currentColor;
        outline: medium auto invert;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    .ContractSelector {
      display: none;
    }
  }

  @media (max-width: $screenMedium) {
    &.expand {
      width: 80vw;
    }

    .SideNavigation__NavItems .ContractSelector {
      display: block;

      .ant-dropdown-trigger {
        width: 100%;

        &.MuiTextField-root .MuiInputBase-root {
          width: 100%;
          border: 1px solid var(--steel-600);
          background: var(--steel-800);
          height: 48px;
        }

        input {
          color: var(--white);

          &::placeholder {
            color: var(--white);
          }
        }
      }
    }

    .SideNavigation__HomeSection {
      padding-left: var(--space-m);

      .HomeSection__NavigationMenu {
        display: block;
        flex-shrink: 0;
        cursor: pointer;

        path {
          fill: var(--white);
        }
      }

      .HomeButton {
        padding: 12px;
      }
    }
  }
}
