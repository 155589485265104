.NavigationItem {
  visibility: hidden;
  .NavigationItem__Link {
    visibility: visible;
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding: var(--space-s);

    .Link__Label {
      color: var(--steel-400);
      font-size: 1rem;
      line-height: 1.375rem;
      white-space: nowrap;
    }

    .Link__Icon {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &.active,
    &:hover {
      .Link__Label {
        color: var(--white);
      }
    }
    &:focus {
      outline: medium auto currentColor;
      outline: medium auto invert;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  &.WmAccordion.outlined {
    background-color: transparent;
    border: none;

    .MuiAccordionSummary-root.WmAccordion__Summary {
      border-radius: 4px;
      padding: 0 var(--space-s) 0 0;

      .Link__Chevron {
        visibility: visible;
        &.active {
          path {
            fill: var(--white);
          }
        }
      }

      &:hover {
        background-color: var(--steel-700);
        color: var(--white);

        .Link__Label,
        .Link__Chevron path {
          color: var(--white);
          fill: var(--white);
        }
      }

      .MuiAccordionSummary-content {
        margin: 0px;
      }
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px;

    .NavigationItem__Children {
      .NavigationItem__Link {
        padding: var(--space-xs) var(--space-m) var(--space-xs) var(--space-xxl);
      }
    }
  }
}
