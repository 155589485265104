.Username {
  cursor: default;

  .Username__name {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .Username__photo {
    position: relative;
  }
}
